import React from "react";
const steps = ["Construcciòn", "Diseño", "Remodelaciòn", ];
const Step = ({ onChange, currentIndex }) => {
  return (
    <div className="steps-container">
      {steps.map((step, index) => {
        let color = currentIndex === index ? "#000" : "#8f8d8d";
        return (
          <div className="steps-item" onClick={ () => onChange(index)}>
            <div
              style={{
                margin: 0,
                color: color
              }}
            >
              {step}
            </div>
          </div>
        );
      })}
    </div>
  );
};
export default Step;