import { useState, useEffect, useRef } from 'react';
import axios from 'axios'
import { isMobile, isAndroid, isIOS } from 'react-device-detect';

import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row'
import Col from 'react-bootstrap/Col'
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Carousel from 'react-bootstrap/Carousel';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';

import { ParallaxProvider, useParallax, Parallax, ParallaxBanner } from 'react-scroll-parallax';
import { AnimationOnScroll } from 'react-animation-on-scroll';
//import AnimatedText from 'react-animated-text-content';

import { portfolio } from './Portfolio'
import Step from "./Step";
import Slider from "./Slider";
import "./styles.css";

import logo_inmobi from './img/logo_inmobi.png'
import rensa from './img/logo.png'
import logo_inmobi_w from './img/logo_inmobi_w.png'
import rensa_w from './img/logo_w.png'
import logo_m from './img/logo_m.png'
import line from './img/line.png'
import title_img from './img/title.png'
import line_sub from './img/line_sub.png'
import arrow_right from './img/arrow-right.png'
import arrow_left from './img/arrow-left.png'
import trazo from './img/trazo.png'
import map from './img/map.png'

import slide1 from './img/slide1.jpg'
import slide2 from './img/slide2.jpg'
import slide3 from './img/slide3.jpg'
import slide4 from './img/slide4.jpg'
import slide5 from './img/slide5.jpg'

import build_1 from './img/building1.png'
import build_2 from './img/building2.png'
import build_3 from './img/building3.png'

import design_1 from './img/design1.png'
import design_2 from './img/design2.png'
import design_3 from './img/design3.png'

import remake_1 from './img/remake1.png'
import remake_2 from './img/remake2.png'
import remake_3 from './img/remake3.png'

import proyect1 from './img/proyect1.png'
import proyect2 from './img/proyect2.png'
import proyect3 from './img/proyect3.png'
import proyect4 from './img/proyect4.png'
import proyect5 from './img/proyect5.jpg'
import proyect6 from './img/proyect6.jpg'
import proyect7 from './img/proyect7.jpg'

import insta from './img/insta.png'
import face from './img/face.png'
import tiktok from './img/tiktok.png'
import linked from './img/linked.png'
import youtube from './img/youtube.png'

import whats from './img/whats.png'
import mail from './img/mail.png'
import location from './img/location.png'

import 'animate.css';
import './App.css';

const API_PATH = 'https://rensaarquitectura.com/mailer.php';
const SITE_KEY = "6LcdJxohAAAAALlj-x-h-rnzFhipgWMFqxAR_W79";

function App() {
  const [ host, setHost ] = useState('http://localhost:3000/')
  const [ activeForm, setActiveForm ] = useState(false)
  const [ currentIndex, setCurrentIndex ] = useState(0);
  const [ proyectIndex, setProyectIndex ] = useState(0);

  const [showMessage, setShowMessage] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [show, setShow] = useState(false);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState('');
  const [showPrivacy, setShowPrivacy] = useState(false);
  const [showPortfolio, setShowPortfolio] = useState(false);
  
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [comments, setComments] = useState("");

  const [ faceLink, setFaceLink ] = useState('https://www.facebook.com/rensaarq')
  const [ instaLink, setInstaLink ] = useState('https://www.instagram.com/rensaarq/')
  const [ tiktokLink, setTiktokLink ] = useState('https://www.tiktok.com/@rensaarq')

  const [ inmobiFaceLink, setInmobiFaceLink ] = useState('https://www.facebook.com/inmobiplace')
  const [ inmobiInstaLink, setInmobiInstaLink ] = useState('https://www.instagram.com/inmobiplace/')

  const [ goingUp, setGoingUp ] = useState(false);
  const prevScrollY = useRef(0);

  const buildRef = useRef(null);
  const designRef = useRef(null);
  const remakeRef = useRef(null);

  const refHome = useRef(null);
  const refUs = useRef(null);
  const refServices = useRef(null);
  const refProyects = useRef(null);
  const refContact = useRef(null);

  useEffect(() => {
    setHost("https://rensaarquitectura.com/")

    console.log(portfolio)
    if ( isMobile ) {
      if( isAndroid ) {
        //setFaceLink('fb://page/111933257823824')
        setInmobiFaceLink('fb://page/104927327626747')
      }
      else if( isIOS ) {
        //setFaceLink('fb://profile/111933257823824')
        setInmobiFaceLink('fb://profile/104927327626747')
      }
    }

    const loadScriptByURL = (id, url, callback) => {
      const isScriptExist = document.getElementById(id);
 
      if (!isScriptExist) {
        var script = document.createElement("script");
        script.type = "text/javascript";
        script.src = url;
        script.id = id;
        script.onload = function () {
          if (callback) callback();
        };
        document.body.appendChild(script);
      }
 
      if (isScriptExist && callback) callback();
    }
 
    // load the script by passing the URL
    loadScriptByURL("recaptcha-key", `https://www.google.com/recaptcha/api.js?render=${SITE_KEY}`, function () {
      console.log("Script loaded!");
    });
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const currentScrollY = window.scrollY;
      if (prevScrollY.current < currentScrollY && goingUp) {
        setGoingUp(false);
      }
      if (prevScrollY.current > currentScrollY && !goingUp) {
        setGoingUp(true);
      }

      prevScrollY.current = currentScrollY;

      if(currentScrollY > 10)
        document.getElementById("rensa-navbar").className = "nav-bar-custom navbar navbar-expand-lg fixed-top";
      else
        document.getElementById("rensa-navbar").className = "nav-bar-custom-start navbar navbar-expand-lg fixed-top";
    };

    window.addEventListener("scroll", handleScroll, { passive: true });

    return () => window.removeEventListener("scroll", handleScroll);
  }, [goingUp]);

  const handleNavClick = (eventKey) => {
    const yOffset = -100; 
    const element = eval(eventKey).current;
    const y = element.getBoundingClientRect().top + window.pageYOffset + yOffset;

    window.scrollTo({top: y, behavior: 'smooth'});
  }

  const handleOnClick = (e) => {
    e.preventDefault();
    setLoading(true);
    window.grecaptcha.ready(() => {
      window.grecaptcha.execute(SITE_KEY, { action: 'submit' }).then(token => {
        handleOnSubmit(token);
      });
    });
  }

  const handleOnSubmit = (token) => {
    var form = document.getElementById('contactForm');
    var data = new FormData(form);
    data.append('recaptcha_response', token);

    axios.post(API_PATH, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
    }).then(function(response) {

      if(response.data == "FAIL" || response.data == "ERROR") {
        setLoading(false)
        if(isMobile) setActiveForm(false)
        setTitle("Error")
        setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
        setShowMessage(true)
      } else {
        setLoading(false)
        if(isMobile) setActiveForm(false)
        setTitle("Mensaje enviado")
        setMessage("Tu mensaje ha sido enviado. En breve nos comunicaremos contigo. Gracias.");
        setShowMessage(true)

        setName("");
        setPhone("");
        setEmail("");
        setComments("");
        setShow(false);
      }

    }).catch(function(err) {
      setLoading(false)
      setTitle("Error")
      setMessage("Ocurrió un error al intentar enviar tu mensaje, por favor intenta mas tarde.");
      setShowMessage(true)
    });
  }

  const handleCloseMessage = () => setShowMessage(false);

  const _handleIndexChange = (index) => {
    var current = document.getElementById("service_" + index);
    var last = document.getElementById("service_" + currentIndex);

    setCurrentIndex(index);
  };

  /*const _handleNext = (currentIndex) => {
    setCurrentIndex(currentIndex + 1);
  };
  const _handleComplete = () => {};
  */

  const handleMenuServices = (action) => {
    if(!isMobile) {
      var slider, step;
      slider = document.getElementById("float_slider");
      step = document.getElementById("float_steps");

      if(action == 'prev') {
        slider.classList.remove("animate__fadeOut");
        slider.classList.remove("send-background");
        slider.classList.add("animate__fadeIn");
        slider.classList.add("send-front");

        step.classList.remove("animate__fadeOut");
        step.classList.remove("send-background");
        step.classList.add("animate__fadeIn");
        step.classList.add("send-front");
      } else {
        slider.classList.remove("animate__fadeIn");
        slider.classList.remove("send-front");
        slider.classList.add("animate__fadeOut");
        slider.classList.add("send-background");
    
        step.classList.remove("animate__fadeIn");
        step.classList.remove("send-front");
        step.classList.add("animate__fadeOut");
        step.classList.add("send-background");
      }
    }
  }

  const onPrevClick = (index) => {
    document.getElementById("build_title_" + index).classList.remove("text-linear-down");
    document.getElementById("build_title_" + index).classList.add("text-linear-up");

    document.getElementById("build_arrow_left_" + index).classList.remove("animate__fadeIn", "send-front")
    document.getElementById("build_arrow_left_" + index).classList.add("animate__fadeOut", "send-background")

    document.getElementById("build_arrow_right_" + index).classList.remove("animate__fadeOut", "send-background")
    document.getElementById("build_arrow_right_" + index).classList.add("animate__fadeIn", "send-front")

    document.getElementById("build_text_" + index).classList.remove("animate__fadeIn")
    document.getElementById("build_text_" + index).classList.add("animate__fadeOut")

    handleMenuServices("prev")
    if (index == 1) buildRef.current.prev();
    else if (index == 2) designRef.current.prev();
    else if (index == 3) remakeRef.current.prev();
  };
  const onNextClick = (index) => {
    document.getElementById("build_title_" + index).classList.remove("text-linear-up");
    document.getElementById("build_title_" + index).classList.add("text-linear-down");

    document.getElementById("build_arrow_left_" + index).classList.remove("animate__fadeOut", "send-background")
    document.getElementById("build_arrow_left_" + index).classList.add("animate__fadeIn", "send-front")

    document.getElementById("build_arrow_right_" + index).classList.remove("animate__fadeIn", "send-front")
    document.getElementById("build_arrow_right_" + index).classList.add("animate__fadeOut", "send-background")

    document.getElementById("build_text_" + index).classList.remove("animate__fadeOut")
    document.getElementById("build_text_" + index).classList.add("animate__fadeIn")

    handleMenuServices("next")
    if (index == 1) buildRef.current.next();
    else if (index == 2) designRef.current.next();
    else if (index == 3) remakeRef.current.next();
  };

  const handleOnSlide = (e) => {
    setProyectIndex(e)
  }

  return (
    <div>
      <Modal show={showMessage} onHide={handleCloseMessage}>
        <Modal.Header style={{ background: "#fff" }} closeButton>
          <Modal.Title style={{ color: "#000" }}>{ title }</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000", background: "#fff" }}>{ message }</Modal.Body>
        <Modal.Footer style={{ color: "#000", background: "#fff" }}>
          <Button variant="secondary" onClick={handleCloseMessage}>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPrivacy} size="lg" onHide={ () => setShowPrivacy(false) }>
        <Modal.Header closeButton>
          <Modal.Title style={{ color: "#000", paddingLeft: "34px" }}>Aviso de privacidad</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ color: "#000", textAlign: "justify" }}>
          Por medio del presente aviso Duro Soluciones S.A. de C.V. con domicilio en C. San Isaias 2, Col. Los Santos, San Miguel de Allende, Gto. C.P. 37745 con fundamento en los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en posesión de particulares le informa y hace de su conocimiento que es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección con las siguientes finalidades: proveer la prestación de servicios y productos que solicite, notificarle sobre promociones y novedades en cuanto a servicios y productos relacionados con los ya contratados o que sean contratados o adquiridos posteriormente, evaluar la calidad del servicio que brindamos y en general para dar cumplimiento a las obligaciones que hemos contraído con usted. Para las finalidades antes mencionadas, requerimos obtener los siguientes datos personales:
          <br/><br/>
          Nombre<br/>
          Número te lefónico <br/>
          Correo electrónico <br/>
          <br/><br/>
          Es importante hacerle de su conocimiento que en cualquier momento tiene el derecho de acceso, rectificación y cancelación de sus datos personales así como a revocar el consentimiento que nos ha otorgado para los fines señalados con anterioridad por lo que es necesario envíe una solicitud al número telefónico de contacto 55 6823 7495 o vía correo electrónico, aplica para el caso de no desear recibir mensajes promocionales.
          <br/><br/>
          Fecha de actualización: 10/05/2022
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={ () => setShowPrivacy(false) }>
            Aceptar
          </Button>
        </Modal.Footer>
      </Modal>

      <Modal show={showPortfolio} size="xl" onHide={ () => setShowPortfolio(false)}>
        <Modal.Header closeButton>
          <Modal.Title><div className='section-title'>Portafolio</div></Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Carousel controls={true} indicators={false} interval={ null } pause={ false }>
            { portfolio.map(item => (
              <Carousel.Item>
                <img className="d-block w-100" src={ item } />
              </Carousel.Item>
            ))}
          </Carousel>
        </Modal.Body>
      </Modal>

      { isMobile &&
        <Modal show={activeForm} onHide={ () => setActiveForm(false) }>
          <Modal.Header style={{ background: "transparent", border: 0 }} closeButton>
          </Modal.Header>
          <Modal.Body style={{ color: "#000" }}>
            <div className='slide-form'>
              <Form id="contactForm" onSubmit={handleOnClick}>
                <Form.Group>
                  <Form.Label className="label-custom">Nombre completo</Form.Label>
                  <Form.Control required name="name" value={name} onChange={ (e) => setName(e.target.value) } />
                </Form.Group>

                <Form.Group>
                  <Form.Label className="label-custom">Correo Electrónico</Form.Label>
                  <Form.Control required type="email" value={email} name="email" onChange={ (e) => setEmail(e.target.value) } />
                </Form.Group>

                <Form.Group>
                  <Form.Label className="label-custom">Teléfono</Form.Label>
                  <Form.Control required type="number" value={phone} name="phone" onChange={ (e) => setPhone(e.target.value) } />
                </Form.Group>

                <Form.Group>
                  <Form.Label className="label-custom">Cuéntanos sobre tu proyecto</Form.Label>
                  <Form.Control required name="comments" as="textarea" value={comments} rows="2" onChange={ (e) => setComments(e.target.value) } />
                </Form.Group>

                <div align="center">
                  <Button variant="outline-primary" type="submit">
                    ENVIAR
                  </Button>
                </div>
              </Form>
            </div>
          </Modal.Body>
        </Modal>
      }

      <Navbar id="rensa-navbar" collapseOnSelect expand="lg" className="nav-bar-custom-start" fixed="top">
        <Container>
          <Navbar.Brand href="#home">
            <img alt="" id="brand_image" src={logo_m} width="auto" height="30" className="d-inline-block align-top brand-image-start" onClick={() => handleNavClick("refHome")}/>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="responsive-navbar-nav" />
          <Navbar.Collapse id="responsive-navbar-nav">
            <Nav className="me-auto">
            </Nav>
            <Nav onSelect={handleNavClick}>
              <Nav.Link eventKey="refUs">
                <div className="nav-link-custom">Nosotros</div>
              </Nav.Link>
              <Nav.Link eventKey="refServices">
                <div className="nav-link-custom">Servicios</div>
              </Nav.Link>
              <Nav.Link eventKey="refProyects">
                <div className="nav-link-custom">Proyectos</div>
              </Nav.Link>
              <Nav.Link eventKey="refContact">
                <div className="nav-link-custom">Contacto</div>
              </Nav.Link>
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container className="home-section" ref={ refHome }>
        <Row className="align-items-center">
          {!isMobile && <Col md={ 2 }></Col> }
          <Col md={ 2 } className="header-title">
            SERVICIO
          </Col>
          <Col md={ 1 } align="center">
            <img src={ line } className="header-img" />
          </Col>
          <Col md={ 2 } className="header-title">
            CALIDAD
          </Col>
          <Col md={ 1 } align="center">
            <img src={ line } className="header-img" />
          </Col>
          <Col md={ 2 } className="header-title">
            COMPROMISO
          </Col>
          {!isMobile && <Col md={ 2 }></Col> }
        </Row>
        <br/>
        {!isMobile && <br/>}
        <Row className="align-items-center">
          <Carousel controls={false} fade={true} indicators={false} interval={5000} pause={ false }>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={ slide1 }
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={ slide2 }
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={ slide3 }
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={ slide4 }
              />
            </Carousel.Item>
            <Carousel.Item>
              <img
                className="d-block w-100"
                src={ slide5 }
              />
            </Carousel.Item>
          </Carousel>
        </Row>
      </Container>

      <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn animateOnce" animateOut="animate__fadeOut">
        <Container className="section" ref={ refUs }>
          <Row>
            <div>
              <img src={ title_img } className="section-title-icon"/>
              <span className="section-title">¿Quiénes somos?</span>
            </div>
          </Row>
          <Row className="align-items-center">
            <Col md={ 6 } xs={ 12 }>            
              <div className="section-desc">
                <br/>
                Somos una empresa con 11 años de experiencia, dedicada al diseño y construcción, con el propósito de ofrecer calidad y compromiso en cada proyecto, aplicando la experiencia de nuestro equipo para así lograr obras fuera de lo ordinario.

                <br/>
                <br/>
                { isMobile &&
                  <div className="slide-logo">
                    <img src={ rensa } className="logo"/>
                    <br/>
                    <br/>
                  </div>
                }
                {!isMobile && <br/>}
                <div>
                  <Button variant="outline-primary" onClick={ (e) => activeForm ? setActiveForm(false) : setActiveForm(true)}>
                    CONTACTO
                  </Button>{' '}
                </div>
                <br/>
                {!isMobile && <hr className='hr-custom'/>}
              </div>
            </Col>
            <Col md={ 6 } xs={ 12 }align="center" className="form-container">
              { !isMobile &&
                <>
                  <div className="slide-logo">
                    <img src={ rensa } className="logo"/>
                  </div>
                  
                  <div className={ activeForm ? "slideRightToLeft show" : "slideRightToLeft" } align="center">
                    <div className='slide-form'>
                      <Form id="contactForm" onSubmit={handleOnClick}>
                        <Form.Group>
                          <Form.Label className="label-custom">Nombre completo</Form.Label>
                          <Form.Control required name="name" value={name} onChange={ (e) => setName(e.target.value) } />
                        </Form.Group>

                        <Form.Group>
                          <Form.Label className="label-custom">Correo Electrónico</Form.Label>
                          <Form.Control required type="email" value={email} name="email" onChange={ (e) => setEmail(e.target.value) } />
                        </Form.Group>

                        <Form.Group>
                          <Form.Label className="label-custom">Teléfono</Form.Label>
                          <Form.Control required type="number" value={phone} name="phone" onChange={ (e) => setPhone(e.target.value) } />
                        </Form.Group>

                        <Form.Group>
                          <Form.Label className="label-custom">Cuéntanos sobre tu proyecto</Form.Label>
                          <Form.Control required name="comments" as="textarea" value={comments} rows="2" onChange={ (e) => setComments(e.target.value) } />
                        </Form.Group>

                        <div align="center">
                          <Button variant="outline-primary" type="submit">
                            ENVIAR
                          </Button>
                        </div>
                      </Form>
                    </div>
                  </div>
                </>
              }
            </Col>
          </Row>
        </Container>
      </AnimationOnScroll>

      <Container className="section" ref={ refServices }>
        <Row>
          <div>
            <img src={ title_img } className="section-title-icon"/>
            <span className="section-title">Lo que hacemos</span>
          </div>
        </Row>
        
        <div style={{ position: "relative" }}>
          { !isMobile &&
            <>
              <div id="float_steps" className="step-names send-front animate__animated"><Step onChange={_handleIndexChange} currentIndex={currentIndex} /></div>
              <div id="float_slider" className="step-slider send-front animate__animated"><Slider onChange={_handleIndexChange} currentIndex={currentIndex} /></div>

              <Container id="service_0" className={ currentIndex == 0 ? "animate__animated animate__fadeIn send-front-90" : "animate__animated animate__fadeOut send-background" }>
                <br/>
                <br/>
                <Carousel controls={false} indicators={false} interval={ null } ref={ buildRef }>
                  <Carousel.Item align="center">
                      <div className="trazo-carousel carousel-item-custom">
                        <ParallaxBanner
                            layers={[
                              {
                                  image: host + 'ccr.png',
                                  speed: -15,
                                  style: {
                                    backgroundSize: isMobile ? 'cover' : 'contain',
                                    backgroundRepeat: 'no-repeat'
                                  },
                              },
                            ]}
                            style={{
                              /*aspectRatio: '2 / 1',*/
                            }}
                        >
                          <Container className="trazo-container">
                            <Row className="align-items-center">
                              <Col>
                                <img src={ trazo } className="trazo-style"/>
                              </Col>
                            </Row>
                          </Container>
                        </ParallaxBanner>
                      </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img id="build_arrow_left_1" src={ arrow_left } className="do-arrow do-arrow-float animate__animated" onClick={ () => onPrevClick(1) }/>
                    <Container>
                      <Row className="align-items-end carousel-item-custom">
                          <Col md={ 4 } align="center">
                            <img src={ build_1 } className="do-img"/>
                          </Col>
                          <Col md={ 4 } align="center">
                            <img src={ build_2 } className="do-img"/>
                          </Col>
                          <Col md={ 4 } align="center">
                            <img src={ build_3 } className="do-img"/>
                          </Col>
                      </Row>
                    </Container>
                  </Carousel.Item>
                </Carousel>
                <div align="right">
                  <br/>
                  <Container style={{ position: "relative" }}>
                    <div className="build-text-float">
                      <div id="build_text_1" className='do-desc animate__animated'>
                        Administraciòn, direcciòn y edificaciòn aplicando soluciones de ingenierìa y arquitectura
                      </div>
                    </div>
                    <Row className="align-items-end do-row">
                      <Col>
                        <div id="build_title_1" className='do-title'>
                          <div>
                            construcciòn
                          </div>
                          <div>
                            comercial y residencial
                          </div>
                        </div>
                        
                      </Col>
                      <Col md="auto" className='do-col'>
                        <div align="left" className='do-arrow-container animate__animated animate__infinite animate__heartBeat'>
                          <img id="build_arrow_right_1" src={ arrow_right } className="do-arrow animate__animated" onClick={ () => onNextClick(1) }/>
                        </div>
                        <div >
                          <img src={ line_sub } className="do-line"/>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Container>

              <Container id="service_1" className={ currentIndex == 1 ? "animate__animated animate__fadeIn send-front-90" : "animate__animated animate__fadeOut send-background" }>
                <br/>
                <br/>
                <Carousel controls={false} indicators={false} interval={ null } ref={ designRef }>
                  <Carousel.Item align="center">
                      <div className="trazo-carousel carousel-item-custom">
                        <ParallaxBanner
                            layers={[
                              {
                                image: host + 'da.png',
                                speed: -15,
                                style: {
                                  backgroundSize: 'contain',
                                  backgroundRepeat: 'no-repeat'
                                },
                              },
                            ]}
                            style={{
                              /*aspectRatio: '2 / 1',*/
                            }}
                        >
                          <Container className="trazo-container">
                            <Row className="align-items-center">
                              <Col>
                                <img src={ trazo } className="trazo-style"/>
                              </Col>
                            </Row>
                          </Container>
                        </ParallaxBanner>
                      </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img id="build_arrow_left_2" src={ arrow_left } className="do-arrow do-arrow-float animate__animated" onClick={ () => onPrevClick(2) }/>
                    <Container>
                      <Row className="align-items-end carousel-item-custom">
                          <Col md={ 4 } align="center">
                            <img src={ design_1 } className="do-img"/>
                          </Col>
                          <Col md={ 4 } align="center">
                            <img src={ design_2 } className="do-img"/>
                          </Col>
                          <Col md={ 4 } align="center">
                            <img src={ design_3 } className="do-img"/>
                          </Col>
                      </Row>
                    </Container>
                    
                  </Carousel.Item>
                </Carousel>
                <div align="right">
                  <br/>
                  <Container style={{ position: "relative" }}>
                    <div className="build-text-float">
                      <div id="build_text_2" className='do-desc animate__animated'>
                        Integrado en armonìa el proyecto con el entorno y la visiòn del cliente, satisfaciendo sus necesidades
                      </div>
                    </div>
                    <Row className="align-items-end do-row">
                      <Col>
                        <div id="build_title_2" className='do-title'>
                          <div>
                            diseño
                          </div>
                          <div>
                            arquitectònico
                          </div>
                        </div>
                        
                      </Col>
                      <Col md="auto" className='do-col'>
                        <div align="left" className='do-arrow-container animate__animated animate__infinite animate__heartBeat'>
                          <img id="build_arrow_right_2" src={ arrow_right } className="do-arrow animate__animated" onClick={ () => onNextClick(2) }/>
                        </div>
                        <div >
                          <img src={ line_sub } className="do-line"/>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Container>

              <Container id="service_2" className={ currentIndex == 2 ? "animate__animated animate__fadeIn send-front-90" : "animate__animated animate__fadeOut send-background" }>
                <br/>
                <br/>
                <Carousel controls={false} indicators={false} interval={ null } ref={ remakeRef }>
                  <Carousel.Item align="center">
                      <div className="trazo-carousel carousel-item-custom">
                        <ParallaxBanner
                            layers={[
                              {
                                  image: host + 'r.png',
                                  speed: -15,
                                  style: {
                                    backgroundSize: 'contain',
                                    backgroundRepeat: 'no-repeat'
                                  },
                              },
                            ]}
                            style={{
                              /*aspectRatio: '2 / 1',*/
                            }}
                        >
                          <Container className="trazo-container">
                            <Row className="align-items-center">
                              <Col>
                                <img src={ trazo } className="trazo-style"/>
                              </Col>
                            </Row>
                          </Container>
                        </ParallaxBanner>
                      </div>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img id="build_arrow_left_3" src={ arrow_left } className="do-arrow do-arrow-float animate__animated" onClick={ () => onPrevClick(3) }/>
                    <Container>
                      <Row className="align-items-end carousel-item-custom">
                          <Col md={ 4 } align="center">
                            <img src={ remake_1 } className="do-img"/>
                          </Col>
                          <Col md={ 4 } align="center">
                            <img src={ remake_2 } className="do-img"/>
                          </Col>
                          <Col md={ 4 } align="center">
                            <img src={ remake_3 } className="do-img"/>
                          </Col>
                      </Row>
                    </Container>
                    
                  </Carousel.Item>
                </Carousel>
                <div align="right">
                  <br/>
                  <Container style={{ position: "relative" }}>
                    <div className="build-text-float">
                      <div id="build_text_3" className='do-desc animate__animated'>
                      Creamos espacios adaptados a las necesidades de cada cliente para mejorar su calidad de vida.
                      </div>
                    </div>
                    <Row className="align-items-end do-row">
                      <Col>
                        <div id="build_title_3" className='do-title'>
                          <div>
                            remodelaciones
                          </div>
                        </div>
                        
                      </Col>
                      <Col md="auto" className='do-col'>
                        <div align="left" className='do-arrow-container animate__animated animate__infinite animate__heartBeat'>
                          <img id="build_arrow_right_3" src={ arrow_right } className="do-arrow animate__animated" onClick={ () => onNextClick(3) }/>
                        </div>
                        <div >
                          <img src={ line_sub } className="do-line"/>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </div>
              </Container>
            </>
          }
          { isMobile &&
            <>
              <br/>
              <Tabs defaultActiveKey="construction" className="mb-3">
                <Tab eventKey="construction" title="Construcciòn">
                  <Container id="service_0" className={ "animate__animated animate__fadeIn" }>
                    <Carousel controls={false} indicators={false} interval={ null } ref={ buildRef }>
                      <Carousel.Item align="center">
                        <br/>
                        <div className="trazo-carousel carousel-item-custom">
                          <ParallaxBanner
                              layers={[
                                {
                                    image: host + 'ccr.png',
                                    speed: -15,
                                    style: {
                                      backgroundSize: 'cover',
                                      backgroundRepeat: 'no-repeat'
                                    },
                                },
                              ]}
                              style={{
                                /*aspectRatio: '2 / 1',*/
                              }}
                          >
                            <Container className="trazo-container">
                              <Row className="align-items-center">
                                <Col>
                                  <img src={ trazo } className="trazo-style"/>
                                </Col>
                              </Row>
                            </Container>
                          </ParallaxBanner>
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <img id="build_arrow_left_1" src={ arrow_left } className="do-arrow do-arrow-float animate__animated" onClick={ () => onPrevClick(1) }/>
                        <Carousel>
                          <Carousel.Item>
                            <img src={ build_1 } className="do-img"/>
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={ build_2 } className="do-img"/>
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={ build_3 } className="do-img"/>
                          </Carousel.Item>
                        </Carousel>
                      </Carousel.Item>
                    </Carousel>
                    <div align="right">
                      <br/>
                      <Container style={{ position: "relative" }}>
                        <div className="build-text-float">
                          <div id="build_text_1" className='do-desc animate__animated'>
                            Administraciòn, direcciòn y edificaciòn aplicando soluciones de ingenierìa y arquitectura
                          </div>
                        </div>
                        <Row className="align-items-end do-row">
                          <Col>
                            <div id="build_title_1" className='do-title'>
                              <div>
                                construcciòn
                              </div>
                              <div>
                                comercial y residencial
                              </div>
                            </div>
                            
                          </Col>
                          <Col md="auto" className='do-col'>
                            <div align="left" className='do-arrow-container animate__animated animate__infinite animate__heartBeat'>
                              <img id="build_arrow_right_1" src={ arrow_right } className="do-arrow animate__animated" onClick={ () => onNextClick(1) }/>
                            </div>
                            <div >
                              <img src={ line_sub } className="do-line"/>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </Container>
                </Tab>

                <Tab eventKey="design" title="Diseño">
                  <Container id="service_1" className={ "animate__animated animate__fadeIn" }>
                    <Carousel controls={false} indicators={false} interval={ null } ref={ designRef }>
                      <Carousel.Item align="center">
                        <br/>
                        <div className="trazo-carousel carousel-item-custom">
                          <ParallaxBanner
                              layers={[
                                {
                                  image: host + 'da.png',
                                  speed: -15,
                                  style: {
                                    backgroundSize: 'cover',
                                    backgroundRepeat: 'no-repeat'
                                  },
                                },
                              ]}
                              style={{
                                /*aspectRatio: '2 / 1',*/
                              }}
                          >
                            <Container className="trazo-container">
                              <Row className="align-items-center">
                                <Col>
                                  <img src={ trazo } className="trazo-style"/>
                                </Col>
                              </Row>
                            </Container>
                          </ParallaxBanner>
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <img id="build_arrow_left_2" src={ arrow_left } className="do-arrow do-arrow-float animate__animated" onClick={ () => onPrevClick(2) }/>
                        <Carousel>
                          <Carousel.Item>
                            <img src={ design_1 } className="do-img"/>
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={ design_2 } className="do-img"/>
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={ design_3 } className="do-img"/>
                          </Carousel.Item>
                        </Carousel>
                        
                      </Carousel.Item>
                    </Carousel>
                    <div align="right">
                      <br/>
                      <Container style={{ position: "relative" }}>
                        <div className="build-text-float">
                          <div id="build_text_2" className='do-desc animate__animated'>
                            Integrado en armonìa el proyecto con el entorno y la visiòn del cleinte, satisfaciendo sus necesidades
                          </div>
                        </div>
                        <Row className="align-items-end do-row">
                          <Col>
                            <div id="build_title_2" className='do-title'>
                              <div>
                                diseño
                              </div>
                              <div>
                                arquitectònico
                              </div>
                            </div>
                            
                          </Col>
                          <Col md="auto" className='do-col'>
                            <div align="left" className='do-arrow-container animate__animated animate__infinite animate__heartBeat'>
                              <img id="build_arrow_right_2" src={ arrow_right } className="do-arrow animate__animated" onClick={ () => onNextClick(2) }/>
                            </div>
                            <div >
                              <img src={ line_sub } className="do-line"/>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </Container>
                </Tab>

                <Tab eventKey="remake" title="Remodelaciòn">
                  <Container id="service_2" className={ "animate__animated animate__fadeIn" }>
                    <Carousel controls={false} indicators={false} interval={ null } ref={ remakeRef }>
                      <Carousel.Item align="center">
                        <br/>
                        <div className="trazo-carousel carousel-item-custom">
                          <ParallaxBanner
                              layers={[
                                {
                                    image: host + 'r.png',
                                    speed: -15,
                                    style: {
                                      backgroundSize: 'cover',
                                      backgroundRepeat: 'no-repeat'
                                    },
                                },
                              ]}
                              style={{
                                /*aspectRatio: '2 / 1',*/
                              }}
                          >
                            <Container className="trazo-container">
                              <Row className="align-items-center">
                                <Col>
                                  <img src={ trazo } className="trazo-style"/>
                                </Col>
                              </Row>
                            </Container>
                          </ParallaxBanner>
                        </div>
                      </Carousel.Item>
                      <Carousel.Item>
                        <img id="build_arrow_left_3" src={ arrow_left } className="do-arrow do-arrow-float animate__animated" onClick={ () => onPrevClick(3) }/>
                        <Carousel>
                          <Carousel.Item>
                            <img src={ remake_1 } className="do-img"/>
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={ remake_2 } className="do-img"/>
                          </Carousel.Item>
                          <Carousel.Item>
                            <img src={ remake_3 } className="do-img"/>
                          </Carousel.Item>
                        </Carousel>
                      </Carousel.Item>
                    </Carousel>
                    <div align="right">
                      <br/>
                      <Container style={{ position: "relative" }}>
                        <div className="build-text-float">
                          <div id="build_text_3" className='do-desc animate__animated'>
                          Creamos espacios adaptados a las necesidades de cada cliente para mejorar su calidad de vida.
                          </div>
                        </div>
                        <Row className="align-items-end do-row">
                          <Col>
                            <div id="build_title_3" className='do-title'>
                              <div>
                                remodelaciones
                              </div>
                            </div>
                            
                          </Col>
                          <Col md="auto" className='do-col'>
                            <div align="left" className='do-arrow-container animate__animated animate__infinite animate__heartBeat'>
                              <img id="build_arrow_right_3" src={ arrow_right } className="do-arrow animate__animated" onClick={ () => onNextClick(3) }/>
                            </div>
                            <div >
                              <img src={ line_sub } className="do-line"/>
                            </div>
                          </Col>
                        </Row>
                      </Container>
                    </div>
                  </Container>
                </Tab>
              </Tabs>
            </>
          }
        </div>

      </Container>

      <Container className="full-container">
        
        <AnimationOnScroll animateOnce={true} animateIn="animate__fadeIn" animateOut="animate__fadeOut">
          <Container className="section proyect-container" ref={ refProyects }>
            <Row>
              <Col md={ 5 }>
                <Container className="proyect-sub-container">
                  <div className="proyect-custom-height"/>

                  <div>
                    <img src={ title_img } className="section-title-icon"/>
                    <span className="section-title">nuestros proyectos</span>
                  </div>
                  
                  <div className="proyect-custom-height"/>

                  {proyectIndex == 0 &&
                    <div id="proyect_1" className={ proyectIndex == 0 ? (isMobile ? "animate__animated animate__fadeIn animate__fast" : "animate__animated animate__fadeInUp send-front-90 animate__fast") : "animate__animated animate__slideOutRight send-background" }>
                      <div className="proyect-header">
                        proyecto residencial
                      </div>
                      <br/>
                      <div className="proyect-title">
                        Cocina Pirules
                      </div>
                      <br/>
                      <div className="proyect-desc">
                        Cocina Colonial: con muro de piedra y barra con talavera.
                        <br/><br/>
                        Cocina Rùstica: muros de piedra, incrustaciones de teja, barro y una barra artesanal.
                        <br/><br/>
                        Cocina - Bar: detalles de espejos, cantera negra y una barra de mezquite.
                      </div>
                    </div>
                  }

                  {proyectIndex == 1 &&
                    <div id="proyect_2" className={ proyectIndex == 1 ? (isMobile ? "animate__animated animate__fadeIn animate__fast" : "animate__animated animate__fadeInUp send-front-90 animate__fast") : "animate__animated animate__slideOutRight send-background" }>
                      <div className="proyect-header">
                        proyecto residencial
                      </div>
                      <br/>
                      <div className="proyect-title">
                        Senda San Miguel
                      </div>
                      <br/>
                      <div className="proyect-desc">
                        Residencia decorada con tonos càlidos y ocres, asì como elementos rùsticos que integran el estilo colonial de San Miguel de Allende.
                      </div>
                    </div>
                  }

                  {proyectIndex == 2 &&
                    <div id="proyect_3" className={ proyectIndex == 2 ? (isMobile ? "animate__animated animate__fadeIn animate__fast" : "animate__animated animate__fadeInUp send-front-90 animate__fast") : "animate__animated animate__slideOutRight send-background" }>
                      <div className="proyect-header">
                        proyecto residencial
                      </div>
                      <br/>
                      <div className="proyect-title">
                        Casa Emily
                      </div>
                      <br/>
                      <div className="proyect-desc">
                        Diseño moderno con acabado aparente de concreto, pensada para aprovechar la luz natural en todas sus direcciones.
                      </div>
                    </div>
                  }

                  {proyectIndex == 3 &&
                    <div id="proyect_4" className={ proyectIndex == 3 ? (isMobile ? "animate__animated animate__fadeIn animate__fast" : "animate__animated animate__fadeInUp send-front-90 animate__fast") : "animate__animated animate__slideOutRight send-background" }>
                      <div className="proyect-header">
                        proyecto residencial
                      </div>
                      <br/>
                      <div className="proyect-title">
                        Casa Hernández
                      </div>
                      <br/>
                      <div className="proyect-desc">
                        El patio central es adornado por un muro lloròn, cerrado con puertas de cristal, dàndole un toque moderno.
                      </div>
                    </div>
                  }

                  {proyectIndex == 4 &&
                    <div id="proyect_4" className={ proyectIndex == 4 ? (isMobile ? "animate__animated animate__fadeIn animate__fast" : "animate__animated animate__fadeInUp send-front-90 animate__fast") : "animate__animated animate__slideOutRight send-background" }>
                      <div className="proyect-header">
                        proyecto residencial
                      </div>
                      <br/>
                      <div className="proyect-title">
                        Casa de la Campana
                      </div>
                      <br/>
                      <div className="proyect-desc">
                        Diseñada con la idea de mantener una iluminaciòn total de las àreas. Haciendo uso de vigas decorativas, elementos de cantera avejentada, puertas y ventanas de madera.
                      </div>
                    </div>
                  }

                  {proyectIndex == 5 &&
                    <div id="proyect_4" className={ proyectIndex == 5 ? (isMobile ? "animate__animated animate__fadeIn animate__fast" : "animate__animated animate__fadeInUp send-front-90 animate__fast") : "animate__animated animate__slideOutRight send-background" }>
                      <div className="proyect-header">
                        proyecto residencial
                      </div>
                      <br/>
                      <div className="proyect-title">
                        Casa Díaz
                      </div>
                      <br/>
                      <div className="proyect-desc">
                        Ampliaciòn de la recàmara principal, terraza y balcones. La terraza està adornada con una fuente de cantera adosada a muro.
                      </div>
                    </div>
                  }

                  {proyectIndex == 6 &&
                    <div id="proyect_4" className={ proyectIndex == 6 ? (isMobile ? "animate__animated animate__fadeIn animate__fast" : "animate__animated animate__fadeInUp send-front-90 animate__fast") : "animate__animated animate__slideOutRight send-background" }>
                      <div className="proyect-header">
                        proyecto residencial
                      </div>
                      <br/>
                      <div className="proyect-title">
                        Terraza viñedos San Lucas
                      </div>
                      <br/>
                      <div className="proyect-desc">
                        Proyecto estilo toscano con tejas y vigas de madera, creado principalmente para àreas de reuniòn y convivencia. 
                      </div>
                    </div>
                  }

                  { isMobile && <br/> }
                </Container>
                
              </Col>
              <Col md={ 7 }>
                <Carousel controls={true} indicators={false} interval={ 8000 } onSlide={ handleOnSlide } pause={ false }>
                  <Carousel.Item>
                    <img src={ proyect1 } className="proyect-img"/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={ proyect2 } className="proyect-img"/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={ proyect3 } className="proyect-img"/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={ proyect4 } className="proyect-img"/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={ proyect5 } className="proyect-img"/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={ proyect6 } className="proyect-img"/>
                  </Carousel.Item>
                  <Carousel.Item>
                    <img src={ proyect7 } className="proyect-img"/>
                  </Carousel.Item>
                </Carousel>
              </Col>

            </Row>
          </Container>
        </AnimationOnScroll>

        <Container className="full-container">
          <Container className="section">
            <div>
              <img src={ title_img } className="section-title-icon"/>
              <span className="section-title">proyectos actuales</span>
            </div>
            <br/>
            <br/>

            <div style={ isMobile ? { padding: "0 2em" } : { padding: "0 4em 0 4em" }}>
              <div className="proyect-header">
                residencial cardón
              </div>
              <br/>
              <Container>
                <Row>
                  <Col md={ 8 } className="gallery-container-1" style={ isMobile ? { padding: "0 0", marginBottom: "2em" } : { padding: "0 2em 0 0", marginBottom: "4em" }}>
                    <ParallaxBanner
                        layers={[
                          {
                              image: host + 'gallery1.jpg',
                              speed: -10,
                              style: {
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                              },
                          },
                        ]}
                        className="gallery-container-1"
                        style={{
                          /*aspectRatio: '2 / 1',*/
                        }}
                    >
                    </ParallaxBanner>
                  </Col>
                  <Col md={ 4 } className="gallery-container-1" style={ isMobile ? { padding: "0 0", marginBottom: "2em" } : { padding: "0 0 0 2em", marginBottom: "4em" }}>
                    <ParallaxBanner
                          layers={[
                            {
                                image: host + 'gallery2.jpg',
                                speed: 5,
                                style: {
                                  backgroundSize: 'cover',
                                  backgroundRepeat: 'no-repeat'
                                },
                            },
                          ]}
                          className="gallery-container-1"
                          style={{
                            /*aspectRatio: '2 / 1',*/
                          }}
                      >
                    </ParallaxBanner>
                  </Col>
                </Row>
                <Row>
                  <Col className="gallery-container-2" style={{ padding: "0" }}>
                    <ParallaxBanner
                        layers={[
                          {
                              image: host + 'gallery3.jpg',
                              speed: -10,
                              style: {
                                backgroundSize: 'cover',
                                backgroundRepeat: 'no-repeat'
                              },
                          },
                        ]}
                        className="gallery-container-2"
                        style={{
                          /*aspectRatio: '2 / 1',*/
                        }}
                    >
                    </ParallaxBanner>
                  </Col>
                </Row>
              </Container>
            </div>
          </Container>
        </Container>

        
        <Container>
          <Container className="section">
            <div>
              <img src={ title_img } className="section-title-icon"/>
              <span className="section-title">ubicación</span>
            </div>
            <br/>
            <br/>
            <Container>
              <Row>
                <Col md={ 9 }>
                <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInLeft" animateOut="animate__fadeOutLeft">
                  <img src={ map } className="map-image"/>
                </AnimationOnScroll>
                </Col>
                <Col md={ 3 }>
                  <AnimationOnScroll animateOnce={true} animateIn="animate__fadeInRight" animateOut="animate__fadeOutRight">
                    <div className="map-desc">
                      <div style={{ widht: "100%" }} align="right">
                        <hr className="hr-short" />
                      </div>
                      <span>Desarrollo de proyectos a nivel nacional.</span>
                    </div>
                  </AnimationOnScroll>
                </Col>
              </Row>
            </Container>
          </Container>
        </Container>
        

        <Container>
          <Container className="section" ref={ refContact }>
            <div>
              <img src={ title_img } className="section-title-icon"/>
              <span className="section-title">Contacto</span>
            </div>
            <br/>
            <br/>
            
            <Container>
              <Row>
                <Col md={ 2 }></Col>
                <Col md={ 8 }>
                  <Container>
                    <Row>
                      <Col align="center">
                        <div className="contact-title">
                            RENSA
                        </div>
                      </Col>
                    </Row>
                    <br/>
                    <Row>
                      <Col align="center">
                        {/* <AnimatedText
                          type="words" // animate words or chars
                          animation={{
                            x: '200px',
                            y: '-20px',
                            scale: 1.1,
                            ease: 'ease-in-out',
                          }}
                          animationType="float"
                          interval={0.06}
                          duration={0.8}
                          tag="p"
                          className="animated-paragraph"
                          includeWhiteSpaces
                          threshold={0.1}
                          rootMargin="20%"
                        >
                            R E N S A
                        </AnimatedText>*/}
                        <div>
                          <img src={ insta } className="contact-social-icons" onClick={ () => window.open( instaLink ) }/>&nbsp;&nbsp;&nbsp;
                          <img src={ face } className="contact-social-icons" onClick={ () => window.open( faceLink ) }/>&nbsp;&nbsp;&nbsp;
                          <img src={ tiktok } className="contact-social-icons" onClick={ () => window.open( tiktokLink ) }/>&nbsp;&nbsp;&nbsp;
                          <img src={ linked } className="contact-social-icons" onClick={ () => window.open( 'https://www.linkedin.com/company/rensa-arquitecturamx/' ) }/>
                        </div>
                      </Col>
                      {/* <Col align="center">
                        <div className="contact-title">
                          INMOBIPLACE
                        </div>
                        <br/>
                        <div>
                          <img src={ insta } className="contact-social-icons" onClick={ () => window.open( inmobiInstaLink ) }/>&nbsp;&nbsp;&nbsp;
                          <img src={ face } className="contact-social-icons" onClick={ () => window.open( inmobiFaceLink ) }/>&nbsp;&nbsp;&nbsp;
                          <img src={ tiktok } className="contact-social-icons" onClick={ () => window.open( 'https://www.tiktok.com/@inmobiplace' ) }/>&nbsp;&nbsp;&nbsp;
                          <img src={ linked } className="contact-social-icons" onClick={ () => window.open( 'https://www.linkedin.com/company/inmobiplace/' ) }/>&nbsp;&nbsp;&nbsp;
                          <img src={ youtube } className="contact-social-icons" onClick={ () => window.open( 'https://www.youtube.com/channel/UC5KgahHC2ioFzv4rPdwQvXA' ) }/>
                        </div>
                      </Col> */}
                      <Col align="center">
                        <div align={ isMobile ? "center" : "left" }>
                          { isMobile && <br/> } 

                          <div className="contact-container" onClick={  () => window.open("https://wa.me/5215625985051")  }>
                            <img src={ whats } className="contact-icon" />&nbsp;&nbsp;&nbsp;
                            <span className="contact-info">56 2598 5051</span>
                          </div>
                          <div className="contact-container" onClick={ () => window.open("mailto:contacto@rensaarquitectura.com") }>
                            <img src={ mail } className="contact-icon" />&nbsp;&nbsp;&nbsp;
                            <span className="contact-info">contacto@rensaarquitectura.com</span>
                          </div>
                        </div>
                      </Col>
                    </Row> 
                    <br/>
                    <br/>
                    <Row>
                      <Col align="center">
                        <div className="contact-title">
                          SAN MIGUEL DE&nbsp;&nbsp;ALLENDE
                        </div>
                        <br/>
                        <div className="contact-container" onClick={ () => window.open("https://goo.gl/maps/WN4ffpHBoqtQdthU9") }>
                          <img src={ location } className="contact-icon" />&nbsp;&nbsp;&nbsp;
                          <span className="contact-info">C. San Isaias 2, Col. Los Santos, 37745 San Miguel de Allende, Gto.</span>
                        </div>
                      </Col>
                        
                      {/* <Col>
                        <div className="contact-title">
                          Ciudad Satèlite
                        </div>
                        <br/>
                        <div className="contact-container" onClick={ () => window.open("https://goo.gl/maps/5xXCWhL45k31S3FT8") }>
                          <img src={ location } className="contact-icon" />&nbsp;&nbsp;&nbsp;
                          <span className="contact-info">Calle, José Guadalupe Posada #16, Cd. Satélite, 53100 Naucalpan de Juárez, Méx.</span>
                        </div>
                      </Col> */}
                    </Row>
                    <br/> 
                    <br/>
                    {/*<Row>
                      <Col>
                        <div>
                          <div className="contact-container" onClick={  () => window.open("https://wa.me/5215625985051")  }>
                            <img src={ whats } className="contact-icon" />&nbsp;&nbsp;&nbsp;
                            <span className="contact-info">56 2598 5051</span>
                          </div>
                          <div className="contact-container" onClick={ () => window.open("mailto:contacto@inmobiplace.com") }>
                            <img src={ mail } className="contact-icon" />&nbsp;&nbsp;&nbsp;
                            <span className="contact-info">contacto@inmobiplace.com</span>
                          </div>
                          <div className="contact-container" onClick={ () => window.open("https://goo.gl/maps/WN4ffpHBoqtQdthU9") }>
                            <img src={ location } className="contact-icon" />&nbsp;&nbsp;&nbsp;
                            <span className="contact-info">C. San Isaias 2, Col. Los Santos, 37745 San Miguel de Allende, Gto.</span>
                          </div>
                        </div>
                      </Col>
                      </Row>*/}
                  </Container>
                </Col>
                <Col md={ 2 }></Col>
              </Row>
            </Container>
          </Container>
        </Container>
      </Container>

      <div className="footer full-container">
        <Container style={ !isMobile ? { width: "20%" } : { width: "100%" } }>
          <Row>
            <Col md={12} xs={12} align="center">
              <img src={ rensa_w } className="footer_logo"/>
            </Col>
            {/* <Col md={6} xs={6} align="center">
              <img src={ logo_inmobi_w } className="footer_logo"/>
            </Col> */}
          </Row>
          <br/>
          <Row>
            <Col align="center">
              <div className='footer-text' onClick={ () => setShowPortfolio(true) }>
                <span>Portafolio</span>
              </div>
              <div className='footer-text' onClick={ () => setShowPrivacy(true) }>
                Aviso de Privacidad
              </div>
            </Col>
          </Row>
        </Container>        
      </div>
    </div>
  );
}

export default App;
